<template>
    <div v-resize="onResize" class="" style="height:100%" ref="container">
        <v-row class="d-flex pl-3" ref="filters">
            <v-col cols="12" sm="6" md="3" lg="3" xl="2" class="d-flex pb-0">
                <div>
                    <v-btn icon small class="mt-9" @click="createXLSX"
                        ><v-icon>mdi-download</v-icon></v-btn
                    >
                </div>
                <div class="ml-n2">
                    <h5 class="mt-2 ml-3">STATISTICS</h5>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                                class="py-0 my-0"
                                v-model="dateRangeText"
                                color="primary"
                                label="Date range"
                                readonly
                                flat
                                solo
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-combobox>
                        </template>
                        <v-date-picker
                            v-model="dates"
                            range
                            scrollable
                            :min="minDate"
                            :max="maxDate"
                        >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                :loading="loading"
                                @click="loadData"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="3"
                lg="3"
                xl="2"
                class="d-flex justify-left pb-0"
            >
                <div>
                    <h5 class="mt-2">PROJECTIONS</h5>
                    <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        hide-details
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                                class="py-0 my-0 ml-n3 mr-4"
                                v-model="dateRangeText2"
                                color="primary"
                                label="Date range"
                                readonly
                                flat
                                solo
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-combobox>
                        </template>
                        <v-date-picker
                            v-model="projectionDates"
                            range
                            scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu2 = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                :loading="loading"
                                @click="loadProjectionsData"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="pb-0">
                <h5 class="mt-2">PERIOD</h5>
                <v-select
                    class="py-0 my-0"
                    max-he
                    :disabled="
                        this.completeQuotes.length == 0 ||
                            this.followUpQuotes.length == 0
                    "
                    prepend-icon="mdi-calendar"
                    v-model="period"
                    :items="periods"
                    label="Period"
                    flat
                    solo
                    text
                    hide-details
                />
            </v-col>
            <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="pb-0">
                <h5 class="mt-2">FILTERS</h5>
                <v-select
                    prepend-icon="mdi-account-group"
                    v-model="value"
                    :items="collaborators"
                    item-text="name"
                    item-value="userId"
                    label="Users"
                    flat
                    solo
                    multiple
                    text
                    hide-details
                >
                    <template v-slot:selection="{ index }">
                        <div v-if="index === 0" class="mr-6">
                            <span>Users</span>
                        </div>
                        <v-badge
                            v-if="index === 0"
                            :content="value.length"
                            :value="collaborators"
                            color="primary"
                            overlap
                            class="mt-n2"
                        >
                        </v-badge>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row class="px-6 mb-4">
            <v-divider />
            <v-progress-linear v-if="loading" indeterminate color="primary" />
        </v-row>
        <div
            class="overflow-y-auto mx-0 px-0 py-0 mt-3"
            :style="`height:${this.height}px`"
        >
            <v-row class="d-flex flex-wrap justify-start">
                <v-col
                    v-for="(state, index) of summaryOfQuotes"
                    :key="index"
                    cols="12"
                    lg="2"
                    md="3"
                    sm="3"
                    class="mx-4"
                    @click="openQuotesBySection(state.type)"
                    style="cursor: pointer"
                >
                    <v-card class="" min-width="220px">
                        <div class="d-flex justify-end my-0 py-0 mx-0 px-0">
                            <span class="text-h5 mr-2 mb-n3">{{
                                state.quantity
                            }}</span>
                        </div>
                        <v-card-text
                            class="d-flex my-0 py-0 mx-0 px-0"
                            width="100%"
                        >
                            <v-chip
                                class="mb-1 mt-0 mx-2 pt-0"
                                :color="state.tagColor"
                                label
                                :text-color="state.textColor"
                                style="height: 50px"
                            >
                                <v-icon center>
                                    {{ state.icon }}
                                </v-icon>
                            </v-chip>
                            <div class="mb-1 mt-0 pt-0">
                                <div class="d-flex mr-0 pr-0">
                                    <span>{{ state.type }}</span>
                                </div>
                                <p class="text-h6 black--text">
                                    {{ state.total }}
                                </p>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="flag" no-gutters class="my-5 mx-3">
                <v-col cols="12" lg="6" md="6" class="pr-2 pl-0">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3 class="ml-2 mb-2">
                            New Quotes ({{ quotesQuantity }})
                        </h3>
                        <highcharts :options="chartQuotes" />
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="pr-2 pl-0">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3 class="ml-2 mb-0" style="cursor: pointer">
                            {{ projectionsTitle }} ({{ totalProjections }})
                        </h3>
                        <highcharts :options="projectionsPieChart" />
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="flag" no-gutters class="mb-5 mx-3">
                <v-col cols="12" lg="6" md="6" class="pr-2 pl-0">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3
                            class="ml-2 mb-0"
                            @click="
                                openQuotesBySection(
                                    'sent',
                                    selectedFollowUpQuotes
                                )
                            "
                            style="cursor: pointer"
                        >
                            Quotes Submitted
                        </h3>
                        <h5 class="ml-2 mb-5 mt-0 pt-0">
                            New ({{ followUpQuotesNew }}) ({{
                                accumulatedValuesOfNewQuotesSent
                            }})- Modified ({{ followUpQuotesModified }}) ({{
                                accumulatedValuesOfModifiedQuotesSent
                            }})
                        </h5>
                        <highcharts :options="ChartColumnSentQuotesQuantity" />
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="pr-2 pl-0">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3
                            class="ml-2 mb-0"
                            @click="
                                openQuotesBySection(
                                    'sent',
                                    selectedFollowUpQuotes
                                )
                            "
                            style="cursor: pointer"
                        >
                            Value of Quotes Submitted
                        </h3>
                        <h5 class="ml-2 mb-5 mt-0 pt-0">
                            ({{
                                new Intl.NumberFormat().format(
                                    parseInt(totalValues)
                                )
                            }})
                        </h5>
                        <highcharts :options="ChartColumnSentQuotesTotals" />
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="flag" no-gutters class="my-5 mx-3">
                <v-col cols="12" lg="6" md="6" class="pr-2 pl-0">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3
                            class="ml-2 mb-2"
                            @click="openQuotesBySection('Awarded')"
                            style="cursor:pointer"
                        >
                            Awarded Quantity ({{
                                acumulativeValue(awardedQuantity)
                            }})
                        </h3>
                        <highcharts :options="chartQuantity" width="100%" />
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="pr-0 pl-2">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3
                            class="ml-2 mb-2"
                            @click="openQuotesBySection('Awarded')"
                            style="cursor:pointer"
                        >
                            Awarded Value ({{
                                new Intl.NumberFormat().format(
                                    parseInt(acumulativeValue(awardedValue))
                                )
                            }})
                        </h3>
                        <highcharts :options="chartValue" />
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="flag" no-gutters class="my-5 mx-3">
                <v-col cols="12" lg="6" md="6" class="pr-0 pl-2">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3 class="ml-2 mb-0">
                            Awarded Per Client ({{
                                acumulativeValue(awardedQuantity)
                            }}) ({{
                                new Intl.NumberFormat().format(
                                    parseInt(acumulativeValue(awardedValue))
                                )
                            }})
                        </h3>
                        <highcharts :options="awardedPerClientPieChart" />
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="pr-0 pl-2">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3 class="ml-2 mb-2">Awarded Per Client</h3>
                        <v-data-table
                            :headers="clientHeaders"
                            :items="filterAwardedPerClient"
                            :items-per-page="10"
                            class="mx-2 my-2"
                            disable-pagination
                            hide-default-footer
                            style="max-height: 350px; overflow-y: auto;"
                        >
                            <template v-slot:[`header.name`]="{ header }">
                                <v-text-field
                                    :label="header.text"
                                    v-model="clientToSearch"
                                />
                            </template>
                            <template v-slot:[`item.color`]="{ item }">
                                <v-btn small icon :color="item.color">
                                    <v-icon>mdi-circle</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:[`item.value`]="{ item }">
                                {{
                                    new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'COP',
                                        currencyDisplay: 'narrowSymbol',
                                        maximumFractionDigits: 0,
                                    }).format(item.value)
                                }}
                            </template>
                            <template v-slot:[`item.percentage`]="{ item }">
                                {{ item.percentage.toFixed(2) }}%
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <!--LOST-->
            <v-row v-if="flag" no-gutters class="my-5 mx-3">
                <v-col cols="12" lg="6" md="6" class="pr-2 pl-0">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3
                            class="ml-2 mb-2"
                            @click="openQuotesBySection('Lost')"
                            style="cursor:pointer"
                        >
                            Lost Quantity ({{ acumulativeValue(lostQuantity) }})
                        </h3>
                        <highcharts :options="chartLostQuantity" width="100%" />
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="pr-0 pl-2">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3
                            class="ml-2 mb-2"
                            @click="openQuotesBySection('Lost')"
                            style="cursor:pointer"
                        >
                            Lost Value ({{
                                new Intl.NumberFormat().format(
                                    parseInt(acumulativeValue(lostValue))
                                )
                            }})
                        </h3>
                        <highcharts :options="chartLostValue" />
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="flag" no-gutters class="my-5 mx-3">
                <v-col cols="12" lg="6" md="6" class="pr-0 pl-2">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3 class="ml-2 mb-0">
                            Reasons For Lost ({{
                                acumulativeValue(lostQuantity)
                            }})
                        </h3>
                        <highcharts :options="lostReasonPieChart" />
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="pr-0 pl-2">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3 class="ml-2 mb-0">
                            Lost Value ({{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: 'COP',
                                }).format(this.totalValue)
                            }})
                        </h3>
                        <highcharts :options="lostReasonValuePieChart" />
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="flag" no-gutters class="mb-5 mx-3">
                <v-col cols="12" class="pr-0 pl-2 mb-3">
                    <v-card class="my-0 py-1" elevation="0">
                        <h3 class="ml-2 mb-2">QUOTES BY USER</h3>
                        <v-data-table
                            :headers="headers"
                            :items="filterQuotesByUser"
                            :items-per-page="10"
                            class="mx-2 my-2"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:[`header.nameUser`]="{ header }">
                                <v-text-field
                                    :label="header.text"
                                    v-model="userName"
                                />
                            </template>
                            <template v-slot:[`header.role`]="{ header }">
                                <v-text-field
                                    :label="header.text"
                                    v-model="userRole"
                                />
                            </template>
                            <template v-slot:[`item.sentValue`]="{ item }">
                                {{
                                    new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'COP',
                                        currencyDisplay: 'narrowSymbol',
                                        maximumFractionDigits: 0,
                                    }).format(item.sentValue)
                                }}
                            </template>
                            <template v-slot:[`item.lostValue`]="{ item }">
                                {{
                                    new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'COP',
                                        currencyDisplay: 'narrowSymbol',
                                        maximumFractionDigits: 0,
                                    }).format(item.lostValue)
                                }}
                            </template>
                            <template v-slot:[`item.awardedValue`]="{ item }">
                                {{
                                    new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: 'COP',
                                        currencyDisplay: 'narrowSymbol',
                                        maximumFractionDigits: 0,
                                    }).format(item.awardedValue)
                                }}
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <!-- Quotes By Section -->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuotesBySection"
            fullscreen
            ref="dialogQuotesBySection"
        >
            <v-card class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToStatistics">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ section }}
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <router-view />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--QUOTE-->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuote"
            fullscreen
            ref="dialogQuote"
        >
            <v-card class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="backToStatistics">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <router-view />
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-alert
            :value="successEmail"
            style="position:fixed; bottom: 30px; right: 0;"
            color="blue"
            dismissible
            type="success"
            transition="scale-transition"
        >
            Quote was successfully sent
        </v-alert>
    </div>
</template>

<script>
import moment from 'moment'
import { Chart } from 'highcharts-vue'
import API from '@/services/api'
import { storage } from '@/services/firebase'
import _ from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import { followUpQuotes, acumulativeValue } from './helpers'

export default {
    name: 'Statistics',
    components: {
        highcharts: Chart,
    },
    data() {
        return {
            selectedFollowUpQuotes: [],
            accumulatedValuesOfNewQuotesSent: 0,
            accumulatedValuesOfModifiedQuotesSent: 0,
            menu2: false,
            followUpQuotesModified: 0,
            followUpQuotesNew: 0,
            chartLostQuantity: {},
            chartLostValue: {},
            awardedQuantity: 0,
            awardedValue: 0,
            lostQuantity: 0,
            lostValue: 0,
            section: '',
            periods: ['Daily', 'Weekly', 'Monthly'],
            period: 'Daily',
            menu: false,
            dates: [],
            dateRanges: null,
            minDate: null,
            maxDate: null,
            loading: false,
            error: false,
            errorMsg: null,
            value: [],
            dialogQuote: false,
            successEmail: false,
            userName: '',
            userRole: '',
            summaryOfQuotes: [
                {
                    type: 'Lost',
                    total: 0,
                    quantity: 0,
                    icon: 'mdi-text-box-remove-outline',
                    tagColor: 'lostTag',
                    textColor: 'lostText',
                },
                {
                    type: 'Awarded',
                    total: 0,
                    quantity: 0,
                    icon: 'mdi-text-box-check-outline',
                    tagColor: 'awardedTag',
                    textColor: 'awardedText',
                },
            ],
            chartQuantity: {},
            chartValue: {},
            chartQuotes: {},
            ChartColumnSentQuotesQuantity: {},
            ChartColumnSentQuotesTotals: {},
            flag: false,
            height: 0,
            headers: [
                {
                    text: 'User',
                    value: 'nameUser',
                    aling: 'start',
                    sortable: false,
                    width: 160,
                },
                {
                    text: 'Role',
                    value: 'role',
                    aling: 'start',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'New',
                    value: 'new',
                    aling: 'start',
                },
                {
                    text: 'Sent',
                    value: 'sent',
                    aling: 'start',
                },
                {
                    text: 'Sent $',
                    value: 'sentValue',
                    aling: 'start',
                },
                {
                    text: 'Lost',
                    value: 'LOST',
                    aling: 'start',
                },
                {
                    text: 'Lost $',
                    value: 'lostValue',
                    aling: 'start',
                },
                {
                    text: 'Awarded',
                    value: 'AWARDED',
                    aling: 'start',
                },
                {
                    text: 'Awarded $',
                    value: 'awardedValue',
                    aling: 'start',
                },
            ],
            simpleQuotes: [],
            quotesByUser: [],
            completeQuotes: [],
            quotesQuantity: 0,
            collaborators: [],
            quotesByState: [],
            totalQuotes: [],
            dialogQuotesBySection: false,
            heightPopUp: 0,
            tempClients: [],
            colors: [
                '#2f7ed8',
                '#0d233a',
                '#8bbc21',
                '#910000',
                '#1aadce',
                '#492970',
                '#f28f43',
                '#77a1e5',
                '#c42525',
                '#a6c96a',
            ],
            lostColors: [
                '#F4370E',
                '#F13737',
                '#FA4D4D',
                '#FF8F7E',
                '#FF794D',
                '#FFA64D',
                '#FFB573',
                '#FFC880',
                '#FFD24D',
                '#FFE66E',
                '#F9FE30',
                '#F4DC0E',
            ],

            clientColors: [
                '#FFF67E',
                '#BFEA7C',
                '#9BCF53',
                '#416D19',
                '#114232',
                '#87A922',
                '#7F9F80',
                '#FFC374',
                '#F9E897',
                '#124076',
                '#D7E4C0',
                '#BBC3A4',
                '#C6DCBA',
            ],

            clientHeaders: [
                {
                    text: 'Color',
                    value: 'color',
                    aling: 'center',
                    sortable: false,
                },
                {
                    text: 'Client',
                    value: 'name',
                    aling: 'start',
                    sortable: false,
                },
                {
                    text: 'Qty',
                    value: 'qty',
                    aling: 'start',
                    sortable: false,
                },
                {
                    text: 'Value',
                    value: 'value',
                    aling: 'start',
                    sortable: false,
                },
                {
                    text: 'Percentage',
                    value: 'percentage',
                    aling: 'start',
                    sortable: false,
                },
            ],

            cardQuote: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderLogo: 'company_logo',
            quoteToArchive: undefined,
            totalQuantity: 0,
            totalValues: 0,
            followUpQuotes: [],
            projectionDates: [],
            projections: [],
            projectionsPieChart: {},
            lostReasonPieChart: {},
            lostReasonValuePieChart: {},
            awardedPerClientPieChart: {},
            awardedDataPerClient: [],
            totalValue: 0,
            clientToSearch: undefined,
            users: [],
        }
    },
    watch: {
        period() {
            this.setPeriodToGraphs()
        },
        dates() {
            if (!this.dates[1]) {
                this.period = 'Daily'
                const arrDate = this.dates[0].split('-')
                let date = new Date(arrDate[0], arrDate[1] - 1, arrDate[2])
                this.minDate = moment(date)
                    .subtract(12, 'months')
                    .toISOString('YYYY-MM-dd')
                    .substring(0, 10)
            } else {
                this.minDate = '2022-02-22'
            }
            this.maxDate = moment(new Date())
                .toISOString('YYYY-MM-dd')
                .substring(0, 10)
        },
        value() {
            this.quotesQuantity = 0
            if (this.value && this.value.length > 0) {
                this.setGraphs()
            } else {
                this.changeData(this.completeQuotes)
                this.quotesQuantity = this.totalQuotes.length
                this.simpleQuotes = this.groupQuotes(this.totalQuotes)
                this.selectedFollowUpQuotes = _.cloneDeep(this.followUpQuotes)
                const data = followUpQuotes(
                    this.dateRanges,
                    this.selectedFollowUpQuotes
                )
                // set values totals
                this.accumulatedValuesOfNewQuotesSent = new Intl.NumberFormat().format(
                    parseInt(data.accumulatedValuesOfNewQuotesSent)
                )
                this.accumulatedValuesOfModifiedQuotesSent = new Intl.NumberFormat().format(
                    parseInt(data.accumulatedValuesOfModifiedQuotesSent)
                )
                // set quantities
                this.totalQuantity = this.selectedFollowUpQuotes.length
                this.ChartColumnSentQuotesQuantity.xAxis.categories = this.dateRanges

                this.followUpQuotesNew = data.unique.reduce(
                    (accumulator, item) => accumulator + item,
                    0
                )

                this.followUpQuotesModified = data.quantity.reduce(
                    (accumulator, item) => accumulator + item,
                    0
                )
                // draw chart
                this.ChartColumnSentQuotesQuantity.series = [
                    {
                        name: 'New',
                        data: data.unique,
                        color: '#efbd21',
                    },
                    {
                        name: 'Modified',
                        data: data.quantity,
                        color: '#CCA11A',
                    },
                ]

                // FOLLOW UP AMOUNT TOTAL
                this.totalValues = data.totals.reduce(
                    (acumulator, item) => item + acumulator,
                    0
                )
                this.ChartColumnSentQuotesTotals.xAxis.categories = this.dateRanges
                this.ChartColumnSentQuotesTotals.series = [
                    {
                        name: 'Value',
                        data: data.totals,
                        color: '#efbd21',
                    },
                ]
                this.chartQuotes.xAxis.categories = this.dateRanges
                this.chartQuotes.series = [
                    {
                        name: 'Quotes',
                        data: Object.values(this.simpleQuotes),
                    },
                ]
                this.setPeriodToGraphs()
            }
        },
    },
    computed: {
        ...mapState(['settings', 'userRef']),
        projectionsTitle() {
            const start = moment(this.projectionDates[0]).format('MMM DD')
            const end = moment(this.projectionDates[1]).format('MMM DD')
            return `Sales projection from ${start} to ${end}`
        },
        totalProjections() {
            if (this.projections.length > 0) {
                const total = this.projections.reduce(
                    (accumulator, projection) =>
                        accumulator + projection.total * projection.exchange,
                    0
                )
                return new Intl.NumberFormat().format(Math.ceil(total))
            }
            return 0
        },
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        dateRangeText2() {
            return this.projectionDates.join(' ~ ')
        },
        filterQuotesByUser() {
            if (this.value.length > 0) {
                let temp = []
                this.value.forEach(element => {
                    temp.push(
                        this.quotesByUser.find(quote => quote.userId == element)
                    )
                })
                return temp
            } else {
                if (this.userName || this.userRole) {
                    return this.quotesByUser.filter(item => {
                        return this.userName && !this.userRole
                            ? item.nameUser
                                  .toLowerCase()
                                  .includes(this.userName.toLowerCase())
                            : this.userRole && !this.userName
                            ? item.role &&
                              item.role
                                  .toLowerCase()
                                  .includes(this.userRole.toLowerCase())
                            : this.userName && this.userRole
                            ? item.nameUser
                                  .toLowerCase()
                                  .includes(this.userName.toLowerCase()) &&
                              item.role &&
                              item.role
                                  .toLowerCase()
                                  .includes(this.userRole.toLowerCase())
                            : false
                    })
                }
                return this.quotesByUser
            }
        },
        filterAwardedPerClient() {
            let filteredData = this.awardedDataPerClient
            if (this.clientToSearch) {
                filteredData = this.awardedDataPerClient.filter(item =>
                    item.name
                        .toLowerCase()
                        .includes(this.clientToSearch.toLowerCase())
                )
            }
            return filteredData
        },
    },
    created() {
        this.projectionsPieChart = {
            chart: {
                type: 'pie',
            },
            title: {
                text: '',
                align: 'left',
            },
            tooltip: {
                headerFormat: '',
                pointFormat:
                    '<span style="color:{point.color}">\u25CF</span> <b style="text-transform: capitalize"> {point.name}</b><br/>' +
                    'Quantity: <b>{point.z}</b><br/>' +
                    'Total: <b>{point.y:.0f}</b><br/>',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format:
                            '<b style="text-transform: capitalize">{point.name} ({point.z})</b><br>{point.y:,.0f}',
                        distance: -50,
                        filter: {
                            property: 'percentage',
                            operator: '>',
                            value: 4,
                        },
                    },
                },
            },
            series: [
                {
                    minPointSize: 10,
                    innerSize: '20%',
                    zMin: 0,
                    name: 'Projections',
                    data: [],
                },
            ],
            credits: {
                enabled: false,
            },
        }
        this.chartQuantity = {
            chart: {
                type: 'column',
            },
            title: {
                text: ``,
            },
            subtitle: {
                text: ``,
            },
            legend: {
                itemStyle: {
                    color: 'black',
                },
            },
            xAxis: {
                categories: [],
                crosshair: false,
                labels: {
                    style: {
                        color: 'black',
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: '<span style="color:black"></span>',
                },
            },
            tooltip: {
                headerFormat:
                    '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                    '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: [],
            credits: {
                enabled: false,
            },
        }

        this.chartValue = {
            chart: {
                type: 'column',
            },
            title: {
                text: ``,
            },
            subtitle: {
                text: ``,
            },
            legend: {
                itemStyle: {
                    color: 'black',
                },
            },
            xAxis: {
                categories: [],
                crosshair: false,
                labels: {
                    style: {
                        color: 'black',
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: '<span style="color:black"></span>',
                },
            },
            tooltip: {
                headerFormat:
                    '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                    '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: [],
            credits: {
                enabled: false,
            },
        }

        /////////////LOST QUOTES///////////////

        this.chartLostQuantity = {
            chart: {
                type: 'column',
            },
            title: {
                text: ``,
            },
            subtitle: {
                text: ``,
            },
            legend: {
                itemStyle: {
                    color: 'black',
                },
            },
            xAxis: {
                categories: [],
                crosshair: false,
                labels: {
                    style: {
                        color: 'black',
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: '<span style="color:black"></span>',
                },
            },
            tooltip: {
                headerFormat:
                    '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                    '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: [],
            credits: {
                enabled: false,
            },
        }

        this.chartLostValue = {
            chart: {
                type: 'column',
            },
            title: {
                text: ``,
            },
            subtitle: {
                text: ``,
            },
            legend: {
                itemStyle: {
                    color: 'black',
                },
            },
            xAxis: {
                categories: [],
                crosshair: false,
                labels: {
                    style: {
                        color: 'black',
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: '<span style="color:black"></span>',
                },
            },
            tooltip: {
                headerFormat:
                    '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                    '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: [],
            credits: {
                enabled: false,
            },
        }

        this.chartQuotes = {
            colors: ['#1976d2'],
            chart: {
                type: 'column',
            },

            legend: {
                symbolWidth: 40,
            },

            title: {
                text: '',
            },

            // subtitle: {
            //     text:
            //         '',
            // },

            yAxis: {
                title: {
                    text: '',
                },
            },

            xAxis: {
                title: {
                    text: '',
                },
                accessibility: {
                    description: '',
                },
                categories: [],
            },

            plotOptions: {
                series: {
                    cursor: 'pointer',
                },
            },

            series: [],
            credits: {
                enabled: false,
            },
        }

        this.ChartColumnSentQuotesQuantity = {
            chart: {
                type: 'column',
            },
            title: {
                text: ``,
            },
            subtitle: {
                text: ``,
            },
            legend: {
                itemStyle: {
                    color: 'black',
                },
            },
            xAxis: {
                categories: [],
                crosshair: false,
                labels: {
                    style: {
                        color: 'black',
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: '<span style="color:black"></span>',
                },
                labels: {
                    padding: 0,
                    margin: 0,
                    y: 0,
                    format: '{value:.1f}',
                    style: {},
                    formatter: function() {
                        return this.value
                    },
                },
            },
            tooltip: {
                headerFormat:
                    '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                    '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: [],
            credits: {
                enabled: false,
            },
        }

        this.ChartColumnSentQuotesTotals = {
            chart: {
                type: 'column',
            },
            title: {
                text: ``,
            },
            subtitle: {
                text: ``,
            },
            legend: {
                itemStyle: {
                    color: 'black',
                },
            },
            xAxis: {
                categories: [],
                crosshair: false,
                labels: {
                    style: {
                        color: 'black',
                    },
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: '<span style="color:black"></span>',
                },
            },
            tooltip: {
                headerFormat:
                    '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                    '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:,.0f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: [],
            credits: {
                enabled: false,
            },
        }
        this.lostReasonPieChart = {
            chart: {
                type: 'pie',
            },
            title: {
                text: '',
                align: 'left',
            },
            tooltip: {
                headerFormat: '',
                pointFormat:
                    '<span style="color:{point.color}">\u25CF</span> <b style="text-transform: capitalize"> {point.name}</b><br/>' +
                    'Quantity: <b>{point.y}</b><br/>' +
                    'Percentage: <b>{point.z:.0f}%</b><br/>',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format:
                            '<b style="text-transform: capitalize">{point.name} ({point.y:,.0f})</b><br>{point.z:.0f}%',
                        distance: -50,
                        filter: {
                            property: 'percentage',
                            operator: '>',
                            value: 4,
                        },
                    },
                },
            },
            series: [
                {
                    minPointSize: 10,
                    innerSize: '20%',
                    zMin: 0,
                    name: 'LostReason',
                    data: [],
                },
            ],
            credits: {
                enabled: false,
            },
        }
        this.lostReasonValuePieChart = {
            chart: {
                type: 'pie',
            },
            title: {
                text: '',
                align: 'left',
            },
            tooltip: {
                headerFormat: '',
                pointFormat:
                    '<span style="color:{point.color}">\u25CF</span> <b style="text-transform: capitalize"> {point.name}</b><br/>' +
                    'Value: <b>{point.y:.0f}</b><br/>' +
                    'Percentage: <b>{point.z:.0f}%</b><br/>',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format:
                            '<b style="text-transform: capitalize">{point.name} ({point.y:,.0f} COP)</b><br>{point.z:.0f}%',
                        distance: -90,
                        filter: {
                            property: 'percentage',
                            operator: '>',
                            value: 4,
                        },
                    },
                },
            },
            series: [
                {
                    minPointSize: 10,
                    innerSize: '20%',
                    zMin: 0,
                    name: 'LostReasonsValue',
                    dataValue: [],
                },
            ],
            credits: {
                enabled: false,
            },
        }
        this.awardedPerClientPieChart = {
            chart: {
                type: 'pie',
            },
            title: {
                text: '',
                align: 'left',
            },
            tooltip: {
                headerFormat: '',
                pointFormat:
                    '<span style="color:{point.color}">\u25CF</span> <b style="text-transform: capitalize"> {point.name}</b><br/>' +
                    'Value: <b>{point.y}</b><br/>' +
                    'Percentage: <b>{point.z:.0f}%</b><br/>',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format:
                            '<b style="text-transform: capitalize">{point.name} ({point.y:,.0f} COP)</b><br>{point.z:.0f}%',
                        distance: -50,
                        filter: {
                            property: 'percentage',
                            operator: '>',
                            value: 4,
                        },
                    },
                },
            },
            series: [
                {
                    minPointSize: 10,
                    innerSize: '20%',
                    zMin: 0,
                    name: 'awardedPerClient',
                    data: [],
                },
            ],
            credits: {
                enabled: false,
            },
        }
    },
    async mounted() {
        try {
            //load users
            const {
                data: { users },
            } = await API.getUsers()
            this.addUsersAction(users)
            this.users = users
            //load clients
            await this.getClients()
            // load statistics
            const now = new Date().toISOString().substring(0, 10)
            let oneWeekAgo = new Date()
            oneWeekAgo = oneWeekAgo.toISOString().substring(0, 8) + '01'
            this.dates.push(oneWeekAgo)
            this.dates.push(now)
            let dt = new Date()
            dt.setMonth(dt.getMonth() + 1)
            this.projectionDates.push(now)
            this.projectionDates.push(dt.toISOString().substring(0, 10))
            await this.loadData()
            await this.loadProjectionsData()
            this.flag = true
            if (this.settings.length == 0) {
                await this.getSettings()
            }
            this.period = 'Weekly'
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    provide() {
        return {
            openQuoteFromTable: this.openQuoteFromTable,
            replaceQuote: this.replaceQuote,
            closeSendQuote: this.closeSendQuote,
            archiveQuote: this.archiveQuote,
            backToMainRoute: this.backToStatistics,
        }
    },
    methods: {
        ...mapActions([
            'addUsersAction',
            'addHistoryCostAction',
            'addSettingsAction',
            'setFromMainBoardAction',
        ]),
        // setValue(data) {
        //     this.value = data
        // },
        ...mapMutations(['setErrorItems']),
        async loadProjectionsData() {
            try {
                this.loading = true
                this.projections = await API.getProjections({
                    startDate: this.projectionDates[0],
                    endDate: this.projectionDates[1],
                })
                const data = [
                    { name: 'low', y: 0, z: 0, color: '#2383BF' },
                    { name: 'medium', y: 0, z: 0, color: '#41E2C3' },
                    { name: 'high', y: 0, z: 0, color: '#23bf85' },
                ]
                this.projections.forEach(projection => {
                    if (
                        projection.probability &&
                        projection.probability.toLowerCase() == 'low'
                    ) {
                        data[0].y += projection.total * projection.exchange
                        data[0].z += 1
                    } else if (
                        projection.probability &&
                        projection.probability.toLowerCase() == 'medium'
                    ) {
                        data[1].y += projection.total * projection.exchange
                        data[1].z += 1
                    } else if (
                        projection.probability &&
                        projection.probability.toLowerCase() == 'high'
                    ) {
                        data[2].y += projection.total * projection.exchange
                        data[2].z += 1
                    }
                })
                let series = _.cloneDeep(this.projectionsPieChart.series)
                series[0] = {
                    name: 'Projections',
                    data,
                }
                this.projectionsPieChart.series = series
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.menu2 = false
            }
        },
        loadLostReasonData(quotes) {
            try {
                this.loading = true
                const quotesIndex = this.settings.findIndex(
                    setting => setting.name === 'Quotes'
                )
                let reasons = []
                if (quotesIndex > -1) {
                    reasons = this.settings[quotesIndex].rejectionReasons
                }
                let data = []
                let dataValue = []
                let totals = 0
                let totalValue = 0
                const nullCategory = {
                    id: null,
                    name: 'No Reason',
                    y: 0,
                    z: 0,
                    color: this.lostColors[0],
                }
                const nullValueCategory = {
                    id: null,
                    name: 'No Reason',
                    y: 0,
                    z: 0,
                    color: this.lostColors[0],
                }
                data.push(nullCategory)
                dataValue.push(nullValueCategory)
                quotes.forEach(quote => {
                    let quoteDate = quote.date.value
                        ? quote.date.value.substring(0, 10)
                        : quote.date.substring(0, 10)
                    const inRange = this.dateRanges.find(
                        date => quoteDate == date
                    )
                    if (inRange !== null && quote.status === 'LOST') {
                        if (quote.rejectionReason !== null) {
                            const existingCategory = data.find(
                                category =>
                                    category.id === quote.rejectionReason
                            )
                            const reason = reasons.find(
                                reason =>
                                    reason.id ===
                                    quote.rejectionReason.replace(
                                        /^"(.*)"$/,
                                        '$1'
                                    )
                            )

                            if (reason !== undefined) {
                                if (existingCategory === undefined) {
                                    const lastIndex = data.length + 1
                                    const newColor = this.getRandomColor()
                                    const newCategory = {
                                        id: quote.rejectionReason,
                                        name: reason.reason,
                                        y: 0,
                                        z: 0,
                                        color:
                                            lastIndex <= this.lostColors.length
                                                ? this.lostColors[lastIndex]
                                                : newColor,
                                    }
                                    const newValueCategory = {
                                        id: quote.rejectionReason,
                                        name: reason.reason,
                                        y: 0,
                                        z: 0,
                                        color:
                                            lastIndex <= this.lostColors.length
                                                ? this.lostColors[lastIndex]
                                                : newColor,
                                    }
                                    data.push(newCategory)
                                    dataValue.push(newValueCategory)
                                }

                                const index = data.findIndex(
                                    category =>
                                        category.id === quote.rejectionReason
                                )

                                totals += 1
                                totalValue += quote.calculatedTotal
                                data[index].y += 1
                                dataValue[index].y += quote.calculatedTotal
                            } else {
                                totals += 1
                                totalValue += quote.calculatedTotal
                                data[0].y += 1
                                dataValue[0].y += quote.calculatedTotal
                            }
                        } else {
                            totals += 1
                            totalValue += quote.calculatedTotal
                            data[0].y += 1
                            dataValue[0].y += quote.calculatedTotal
                        }
                        this.totalValue = totalValue
                        data.forEach(data => {
                            data.z = (data.y * 100) / totals
                        })
                        dataValue.forEach(dataValue => {
                            dataValue.z = (dataValue.y * 100) / totalValue
                        })
                    }
                })

                let series = _.cloneDeep(this.lostReasonPieChart.series)
                series[0] = {
                    name: 'LostReason',
                    data,
                }
                this.lostReasonPieChart.series = series

                series = _.cloneDeep(this.lostReasonValuePieChart.series)
                series[0] = {
                    name: 'LostReasonsValue',
                    data: dataValue,
                }
                this.lostReasonValuePieChart.series = series
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.menu2 = false
            }
        },
        loadAwardedPerClientData(quotes) {
            try {
                this.loading = true
                let data = []
                let dataValues = []
                let totalValue = 0
                quotes.forEach(quote => {
                    let quoteDate = quote.date.value
                        ? quote.date.value.substring(0, 10)
                        : quote.date.substring(0, 10)
                    const inRange = this.dateRanges.find(
                        date => quoteDate == date
                    )
                    if (inRange !== null && quote.status === 'AWARDED') {
                        if (quote.client) {
                            const existingCategory = data.find(
                                category => category.id === quote.client.id
                            )

                            if (existingCategory === undefined) {
                                const lastIndex = data.length + 1
                                const newColor = this.getRandomColor()
                                const newCategory = {
                                    id: quote.client.id,
                                    name: quote.client.name,
                                    y: 0,
                                    z: 0,
                                    color:
                                        lastIndex <= this.clientColors.length
                                            ? this.clientColors[lastIndex]
                                            : newColor,
                                }
                                const newDataCategory = {
                                    id: quote.client.id,
                                    name: quote.client.name,
                                    qty: 0,
                                    value: 0,
                                    percentage: 0,
                                    color:
                                        lastIndex <= this.clientColors.length
                                            ? this.clientColors[lastIndex]
                                            : newColor,
                                }
                                data.push(newCategory)
                                dataValues.push(newDataCategory)
                            }

                            const index = data.findIndex(
                                category => category.id === quote.client.id
                            )

                            totalValue += quote.calculatedTotal
                            data[index].y += quote.calculatedTotal
                            dataValues[index].value += quote.calculatedTotal
                            dataValues[index].qty += 1
                        }
                        data.forEach(data => {
                            data.z = (data.y * 100) / totalValue
                        })
                        dataValues.forEach(dataValue => {
                            dataValue.percentage =
                                (dataValue.value * 100) / totalValue
                        })
                    }
                })
                let series = _.cloneDeep(this.awardedPerClientPieChart.series)
                series[0] = {
                    name: 'awardedPerClient',
                    data,
                }
                series[0].data.sort((a, b) => b.y - a.y)
                this.awardedPerClientPieChart.series = series
                dataValues.sort((a, b) => b.value - a.value)
                this.awardedDataPerClient = dataValues
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.menu2 = false
            }
        },
        getRandomColor() {
            const letters = '0123456789ABCDEF'
            let color = '#'
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)]
            }
            return color
        },
        acumulativeValue(value) {
            return acumulativeValue(value)
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                filters: { clientHeight: filtersHeight },
            } = this.$refs
            this.height = containerHeight - filtersHeight - 3
            this.heightPopUp = window.innerHeight - 62
        },
        async loadData() {
            try {
                this.value = []
                this.loading = true
                this.error = false
                this.errorMsg = null
                this.awardedQuantity = 0
                this.awardedValue = 0
                this.lostQuantity = 0
                this.lostValue = 0
                this.totalQuantity = 0
                this.totalValues = 0
                this.completeQuotes = []
                this.totalQuotes = []
                this.quotesQuantity = 0
                if (
                    Number(this.dates[0].substring(0, 4)) >=
                    Number(this.dates[1].substring(0, 4))
                ) {
                    if (
                        Number(this.dates[0].substring(5, 7)) >=
                        Number(this.dates[1].substring(5, 7))
                    ) {
                        if (
                            Number(this.dates[0].substring(8, 10)) >=
                            Number(this.dates[1].substring(8, 10))
                        ) {
                            let dateTemp = this.dates[0]
                            this.dates[0] = this.dates[1]
                            this.dates[1] = dateTemp
                        }
                    }
                }
                this.dateRanges = this.daysBetweenDates(
                    moment(this.dates[0]),
                    moment(this.dates[1])
                )
                this.menu = false
                const {
                    data: { quotes: completeQuotes },
                } = await API.getCompleteQuotes(this.dates[0], this.dates[1])
                if (completeQuotes.length > 0) {
                    this.completeQuotes = completeQuotes
                    await this.completeQuotesData(this.completeQuotes)
                    this.changeData(this.completeQuotes)
                }

                //CHART QUOTES
                const {
                    data: { quotes: simpleQuotes },
                } = await API.getSimpleQuotes(this.dates[0], this.dates[1])
                if (simpleQuotes.length > 0) {
                    this.quotesQuantity = simpleQuotes.length
                    this.totalQuotes = simpleQuotes
                    this.simpleQuotes = this.groupQuotes(this.totalQuotes)
                    this.chartQuotes.xAxis.categories = this.dateRanges
                    this.chartQuotes.series = [
                        {
                            name: 'Quotes',
                            data: Object.values(this.simpleQuotes),
                        },
                    ]
                }
                // FOLLOW UP QUOTES
                const {
                    data: { quotes: sentQuotes },
                } = await API.getSentQuotes(this.dates[0], this.dates[1])
                this.followUpQuotes = sentQuotes
                await this.completeQuotesData(this.followUpQuotes)
                this.selectedFollowUpQuotes = _.cloneDeep(this.followUpQuotes)
                const data = followUpQuotes(this.dateRanges, sentQuotes)
                // set values totals
                this.accumulatedValuesOfNewQuotesSent = new Intl.NumberFormat().format(
                    parseInt(data.accumulatedValuesOfNewQuotesSent)
                )
                this.accumulatedValuesOfModifiedQuotesSent = new Intl.NumberFormat().format(
                    parseInt(data.accumulatedValuesOfModifiedQuotesSent)
                )
                // set quantities
                this.totalQuantity = sentQuotes.length
                this.ChartColumnSentQuotesQuantity.xAxis.categories = this.dateRanges

                this.followUpQuotesNew = data.unique.reduce(
                    (accumulator, item) => accumulator + item,
                    0
                )

                this.followUpQuotesModified = data.quantity.reduce(
                    (accumulator, item) => accumulator + item,
                    0
                )
                // draw chart
                this.ChartColumnSentQuotesQuantity.series = [
                    {
                        name: 'New',
                        data: data.unique,
                        color: '#efbd21',
                    },
                    {
                        name: 'Modified',
                        data: data.quantity,
                        color: '#CCA11A',
                    },
                ]

                // FOLLOW UP AMOUNT TOTAL
                this.totalValues = data.totals.reduce(
                    (acumulator, item) => item + acumulator,
                    0
                )
                this.ChartColumnSentQuotesTotals.xAxis.categories = this.dateRanges
                this.ChartColumnSentQuotesTotals.series = [
                    {
                        name: 'Value',
                        data: data.totals,
                        color: '#efbd21',
                    },
                ]
                // QUOTES BY USER
                this.quotesByUser = await this.groupQuotesByUser([
                    ...simpleQuotes,
                    ...this.completeQuotes,
                ])
                this.period = 'Weekly'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async setGraphs() {
            const lAQuotes = this.completeQuotes.filter(
                quote => quote.status === 'LOST' || quote.status === 'AWARDED'
            )
            let lostAndAwardedQuotes = []
            let sentQuotes = []
            let newQuotes = []
            this.value.forEach(element => {
                lostAndAwardedQuotes = [
                    ...lostAndAwardedQuotes,
                    ...lAQuotes.filter(
                        quote =>
                            quote.userId == element ||
                            (!!quote.collaborators &&
                                quote.collaborators.includes(element))
                    ),
                ]
                sentQuotes = [
                    ...sentQuotes,
                    ...this.followUpQuotes.filter(
                        quote =>
                            quote.userId == element ||
                            (!!quote.collaborators &&
                                quote.collaborators.includes(element))
                    ),
                ]
                newQuotes = [
                    ...newQuotes,
                    ...this.totalQuotes.filter(
                        quote =>
                            quote.userId == element ||
                            (!!quote.collaborators &&
                                quote.collaborators.includes(element))
                    ),
                ]
            })
            this.selectedFollowUpQuotes = _.cloneDeep(sentQuotes)
            //LOST & AWARDED QUOTES
            this.changeData(lostAndAwardedQuotes)
            //SENT VALUE QUOTES
            const sentQuotesAux = followUpQuotes(this.dateRanges, sentQuotes)
            // set values totals
            this.accumulatedValuesOfNewQuotesSent = new Intl.NumberFormat().format(
                parseInt(sentQuotesAux.accumulatedValuesOfNewQuotesSent)
            )
            this.accumulatedValuesOfModifiedQuotesSent = new Intl.NumberFormat().format(
                parseInt(sentQuotesAux.accumulatedValuesOfModifiedQuotesSent)
            )
            this.followUpQuotesNew = sentQuotesAux.unique.reduce(
                (accumulator, item) => (accumulator += item),
                0
            )

            this.followUpQuotesModified = sentQuotesAux.quantity.reduce(
                (accumulator, item) => (accumulator += item),
                0
            )
            this.totalValues = sentQuotesAux.totals.reduce(
                (acumulator, item) => item + acumulator,
                0
            )
            this.ChartColumnSentQuotesQuantity.xAxis.categories = this.dateRanges
            this.ChartColumnSentQuotesQuantity.series = [
                {
                    name: 'New',
                    data: sentQuotesAux.unique,
                    color: '#efbd21',
                },
                {
                    name: 'Modified',
                    data: sentQuotesAux.quantity,
                    color: '#CCA11A',
                },
            ]
            //SENT VALUE QUOTES
            this.ChartColumnSentQuotesTotals.xAxis.categories = this.dateRanges
            this.ChartColumnSentQuotesTotals.series = [
                {
                    name: 'Value',
                    data: sentQuotesAux.totals,
                    color: '#efbd21',
                },
            ]
            //NEW QUOTES
            this.quotesQuantity = newQuotes.length
            this.simpleQuotes = this.groupQuotes(newQuotes)
            this.chartQuotes.xAxis.categories = this.dateRanges
            this.chartQuotes.series = [
                {
                    name: 'Quotes',
                    data: Object.values(this.simpleQuotes),
                },
            ]
            this.setPeriodToGraphs(sentQuotes)
        },
        setPeriodToGraphs(quotes = this.followUpQuotes) {
            //TODO: Recalculate sent totals and open new quotes in the table
            const sentQuotes = followUpQuotes(this.dateRanges, quotes)
            if (this.period == 'Daily') {
                this.chartQuantity.xAxis.categories = this.dateRanges
                this.chartQuantity.series = [
                    {
                        name: 'Awarded',
                        data: this.awardedQuantity,
                        color: '#23bf85',
                    },
                ]
                this.chartValue.xAxis.categories = this.dateRanges
                this.chartValue.series = [
                    {
                        name: 'Awarded',
                        data: this.awardedValue,
                        color: '#23bf85',
                    },
                ]
                this.chartLostQuantity.xAxis.categories = this.dateRanges
                this.chartLostQuantity.series = [
                    {
                        name: 'Lost',
                        data: this.lostQuantity,
                        color: '#db4d42',
                    },
                ]
                this.chartLostValue.xAxis.categories = this.dateRanges
                this.chartLostValue.series = [
                    {
                        name: 'Lost',
                        data: this.lostValue,
                        color: '#db4d42',
                    },
                ]
                this.ChartColumnSentQuotesQuantity.xAxis.categories = this.dateRanges
                this.ChartColumnSentQuotesQuantity.series = [
                    {
                        name: 'New',
                        data: sentQuotes.unique,
                        color: '#efbd21',
                    },
                    {
                        name: 'Modified',
                        data: sentQuotes.quantity,
                        color: '#CCA11A',
                    },
                ]
                this.ChartColumnSentQuotesTotals.xAxis.categories = this.dateRanges
                this.ChartColumnSentQuotesTotals.series = [
                    {
                        name: 'Value',
                        data: sentQuotes.totals,
                        color: '#efbd21',
                    },
                ]
                this.chartQuotes.xAxis.categories = this.dateRanges
                this.chartQuotes.series = [
                    {
                        name: 'Quotes',
                        data: Object.values(this.simpleQuotes),
                    },
                ]
                return
            }
            const newQuotes = Object.values(this.simpleQuotes)
            let newRanges = [this.dateRanges[0]]
            let awardedQuantity = []
            let awardedValue = []
            let lostQuantity = []
            let lostValue = []
            let sentUniqueQuantity = []
            let sentModifiedQuantity = []
            let sentValue = []
            let newQuantity = []
            let index = 0
            if (this.period == 'Weekly') {
                this.dateRanges.forEach((date, i) => {
                    if (moment(date, 'YYYY-MM-DD').day() == 1 && i > 0) {
                        index++
                        newRanges[index] = date
                    }
                    if (awardedQuantity[index])
                        awardedQuantity[index] += this.awardedQuantity[i]
                    else awardedQuantity[index] = this.awardedQuantity[i]
                    if (awardedValue[index])
                        awardedValue[index] += this.awardedValue[i]
                    else awardedValue[index] = this.awardedValue[i]

                    if (lostQuantity[index])
                        lostQuantity[index] += this.lostQuantity[i]
                    else lostQuantity[index] = this.lostQuantity[i]
                    if (lostValue[index]) lostValue[index] += this.lostValue[i]
                    else lostValue[index] = this.lostValue[i]

                    if (sentUniqueQuantity[index])
                        sentUniqueQuantity[index] += sentQuotes.unique[i]
                    else sentUniqueQuantity[index] = sentQuotes.unique[i]
                    if (sentModifiedQuantity[index])
                        sentModifiedQuantity[index] += sentQuotes.quantity[i]
                    else sentModifiedQuantity[index] = sentQuotes.quantity[i]
                    if (sentValue[index])
                        sentValue[index] += sentQuotes.totals[i]
                    else sentValue[index] = sentQuotes.totals[i]

                    if (newQuantity[index]) newQuantity[index] += newQuotes[i]
                    else newQuantity[index] = newQuotes[i]
                })
            } else if (this.period == 'Monthly') {
                let month = moment(this.dateRanges[0], 'YYYY-MM-DD').month()
                this.dateRanges.forEach((date, i) => {
                    if (moment(date, 'YYYY-MM-DD').month() != month && i > 0) {
                        month = moment(date, 'YYYY-MM-DD').month()
                        index++
                        newRanges[index] = date
                    }
                    if (awardedQuantity[index])
                        awardedQuantity[index] += this.awardedQuantity[i]
                    else awardedQuantity[index] = this.awardedQuantity[i]
                    if (awardedValue[index])
                        awardedValue[index] += this.awardedValue[i]
                    else awardedValue[index] = this.awardedValue[i]

                    if (lostQuantity[index])
                        lostQuantity[index] += this.lostQuantity[i]
                    else lostQuantity[index] = this.lostQuantity[i]
                    if (lostValue[index]) lostValue[index] += this.lostValue[i]
                    else lostValue[index] = this.lostValue[i]

                    if (sentUniqueQuantity[index])
                        sentUniqueQuantity[index] += sentQuotes.unique[i]
                    else sentUniqueQuantity[index] = sentQuotes.unique[i]
                    if (sentModifiedQuantity[index])
                        sentModifiedQuantity[index] += sentQuotes.quantity[i]
                    else sentModifiedQuantity[index] = sentQuotes.quantity[i]
                    if (sentValue[index])
                        sentValue[index] += sentQuotes.totals[i]
                    else sentValue[index] = sentQuotes.totals[i]

                    if (newQuantity[index]) newQuantity[index] += newQuotes[i]
                    else newQuantity[index] = newQuotes[i]
                })
            }

            this.chartQuantity.xAxis.categories = newRanges
            this.chartQuantity.series = [
                {
                    name: 'Awarded',
                    data: awardedQuantity,
                    color: '#23bf85',
                },
            ]
            this.chartValue.xAxis.categories = newRanges
            this.chartValue.series = [
                {
                    name: 'Awarded',
                    data: awardedValue,
                    color: '#23bf85',
                },
            ]
            this.chartLostQuantity.xAxis.categories = newRanges
            this.chartLostQuantity.series = [
                {
                    name: 'Lost',
                    data: lostQuantity,
                    color: '#db4d42',
                },
            ]
            this.chartLostValue.xAxis.categories = newRanges
            this.chartLostValue.series = [
                {
                    name: 'Lost',
                    data: lostValue,
                    color: '#db4d42',
                },
            ]

            this.ChartColumnSentQuotesQuantity.xAxis.categories = newRanges
            this.ChartColumnSentQuotesQuantity.series = [
                {
                    name: 'New',
                    data: sentUniqueQuantity,
                    color: '#efbd21',
                },
                {
                    name: 'Modified',
                    data: sentModifiedQuantity,
                    color: '#CCA11A',
                },
            ]

            this.ChartColumnSentQuotesTotals.xAxis.categories = newRanges
            this.ChartColumnSentQuotesTotals.series = [
                {
                    name: 'Value',
                    data: sentValue,
                    color: '#efbd21',
                },
            ]
            this.chartQuotes.xAxis.categories = newRanges
            this.chartQuotes.series = [
                {
                    name: 'Quotes',
                    data: newQuantity,
                },
            ]
        },
        daysBetweenDates(startDate, endDate) {
            this.datesObject = []
            let currentDay = startDate
            let dates = []
            while (currentDay.isSameOrBefore(endDate)) {
                dates.push(currentDay.format('YYYY-MM-DD'))
                this.datesObject[currentDay.format('YYYY-MM-DD')] = 0
                currentDay.add(1, 'days')
            }
            return dates
        },
        groupQuotes(quotes) {
            let obj = {}
            for (let date of this.dateRanges) {
                const subArr = quotes.filter(quote => {
                    let quoteDate = quote.date.value.substring(0, 10)
                    return quoteDate == date ? true : false
                })
                if (subArr.length > 0) {
                    obj[date] = subArr.length
                } else {
                    obj[date] = 0
                }
            }
            return obj
        },
        groupQuotesByState(quotes) {
            const defaultStructure = {}
            this.dateRanges.forEach(date => {
                defaultStructure[date] = {
                    quantity: 0,
                    total: 0,
                }
            })
            let obj = {
                lost: _.cloneDeep(defaultStructure),
                awarded: _.cloneDeep(defaultStructure),
            }
            for (let date of this.dateRanges) {
                for (let quote of quotes) {
                    let quoteDate = quote.date.value
                        ? quote.date.value.substring(0, 10)
                        : quote.date.substring(0, 10)
                    if (quote.status == 'LOST' && quoteDate == date) {
                        obj.lost[date].quantity = obj.lost[date].quantity + 1
                        obj.lost[date].total =
                            obj.lost[date].total +
                            (quote.calculatedTotal != null
                                ? quote.calculatedTotal
                                : 0)
                    } else if (quote.status == 'AWARDED' && quoteDate == date) {
                        obj.awarded[date].quantity =
                            obj.awarded[date].quantity + 1
                        obj.awarded[date].total =
                            obj.awarded[date].total +
                            (quote.calculatedTotal != null
                                ? quote.calculatedTotal
                                : 0)
                    }
                }
            }
            for (let state of Object.keys(obj)) {
                for (let date of this.dateRanges) {
                    obj[state][date].total = parseInt(obj[state][date].total)
                }
            }
            return obj
        },
        async groupQuotesByUser() {
            let arr = []
            const awardedAndLostQuotes = this.completeQuotes.filter(
                quote => quote.status === 'AWARDED' || quote.status === 'LOST'
            )
            awardedAndLostQuotes.forEach(quote => {
                if (quote.collaborators)
                    quote.collaborators.forEach(userId => {
                        const index = arr.findIndex(a => a.userId == userId)
                        if (index >= 0) {
                            let status
                            status = quote.status
                            arr[index][status] += 1
                            arr[index][status.toLowerCase() + 'Value'] +=
                                quote.calculatedTotal
                        } else {
                            const obj = {
                                userId,
                                LOST: 0,
                                AWARDED: 0,
                                lostValue: 0,
                                awardedValue: 0,
                                new: 0,
                                sent: 0,
                                sentValue: 0,
                            }
                            const status = quote.status
                            obj[status] = 1
                            obj[status.toLowerCase() + 'Value'] =
                                quote.calculatedTotal
                            arr.push(obj)
                        }
                    })
            })
            // retrieve user data
            this.totalQuotes.forEach(quote => {
                const index = arr.findIndex(a => a.userId == quote.userId)
                if (index >= 0) {
                    arr[index]['new'] += 1
                } else {
                    const obj = {
                        userId: quote.userId,
                        LOST: 0,
                        AWARDED: 0,
                        lostValue: 0,
                        awardedValue: 0,
                        new: 0,
                        sent: 0,
                        sentValue: 0,
                    }
                    obj['new'] = 1
                    arr.push(obj)
                }
            })
            this.followUpQuotes.forEach(quote => {
                if (quote.collaborators)
                    quote.collaborators.forEach(userId => {
                        const index = arr.findIndex(a => a.userId == userId)
                        if (index >= 0) {
                            arr[index]['sent'] += 1
                            arr[index]['sentValue'] += quote.calculatedTotal
                        } else {
                            const obj = {
                                userId,
                                LOST: 0,
                                AWARDED: 0,
                                lostValue: 0,
                                awardedValue: 0,
                                new: 0,
                                sent: 0,
                                sentValue: 0,
                            }
                            obj['sent'] = 1
                            obj['sentValue'] = quote.calculatedTotal
                            arr.push(obj)
                        }
                    })
            })
            let tempUsers
            if (this.users.length > 0) {
                tempUsers = this.users.slice()
            } else {
                const {
                    data: { users },
                } = await API.getUsers()

                this.users = users
                tempUsers = users.slice()
            }
            arr.forEach(element => {
                const dataUser = tempUsers.find(
                    user => user.id == element.userId
                )
                if (dataUser) {
                    element.nameUser = dataUser.name
                    element.role = dataUser.role
                    this.collaborators.push({
                        userId: dataUser.id,
                        name: dataUser.name,
                        role: dataUser.role,
                    })
                } else {
                    element.nameUser = element.userId
                }
            })
            return arr
        },
        calculateSummaryQuotes(quotes) {
            let arr = []
            quotes.forEach(quote => {
                const index = arr.findIndex(
                    element => element.status == quote.status
                )
                if (index >= 0) {
                    arr[index].quantity += 1
                    arr[index].total += quote.calculatedTotal
                } else {
                    arr.push({
                        status: quote.status,
                        quantity: 1,
                        total: quote.calculatedTotal,
                    })
                }
            })
            // new Intl.NumberFormat().format(number)
            this.summaryOfQuotes.forEach(quote => {
                const resumeQuote = arr.find(element => {
                    return element.status == quote.type.toUpperCase()
                })
                quote.quantity = resumeQuote ? resumeQuote.quantity : 0
                quote.total = resumeQuote
                    ? new Intl.NumberFormat().format(
                          parseInt(resumeQuote.total)
                      )
                    : 0
            })
        },
        changeData(quotes) {
            // Resume
            this.calculateSummaryQuotes(quotes)
            // group quotes by state
            this.quotesByState = this.groupQuotesByState(quotes)
            // CHART QUANTITY
            this.chartQuantity.xAxis.categories = this.dateRanges
            this.awardedQuantity = Object.values(
                this.quotesByState.awarded
            ).map(element => element.quantity)
            this.chartQuantity.series = [
                {
                    name: 'Awarded',
                    data: this.awardedQuantity,
                    color: '#23bf85',
                },
            ]
            // CHART VALUES
            this.chartValue.xAxis.categories = this.dateRanges
            this.awardedValue = Object.values(this.quotesByState.awarded).map(
                element => element.total
            )
            this.chartValue.series = [
                {
                    name: 'Awarded',
                    data: this.awardedValue,
                    color: '#23bf85',
                },
            ]

            //////////LOST QUOTES/////////////

            // CHART QUANTITY
            this.chartLostQuantity.xAxis.categories = this.dateRanges
            this.lostQuantity = Object.values(this.quotesByState.lost).map(
                element => element.quantity
            )
            this.chartLostQuantity.series = [
                {
                    name: 'Lost',
                    data: this.lostQuantity,
                    color: '#db4d42',
                },
            ]
            // CHART VALUES
            this.chartLostValue.xAxis.categories = this.dateRanges
            this.lostValue = Object.values(this.quotesByState.lost).map(
                element => element.total
            )
            this.chartLostValue.series = [
                {
                    name: 'Lost',
                    data: this.lostValue,
                    color: '#db4d42',
                },
            ]
            // lost reasons
            this.loadLostReasonData(quotes)
            // awarded per client
            this.loadAwardedPerClientData(quotes)
        },
        async createXLSX() {
            try {
                this.loading = true
                const {
                    data: { clients },
                } = await API.getLiteClients()
                const copySimpleQuotes = _.cloneDeep(this.totalQuotes)
                const copyCompleteQuotes = _.cloneDeep(this.completeQuotes)
                const clientQuotes = _.cloneDeep(this.awardedDataPerClient)
                const data1 = copyCompleteQuotes.map(quote => {
                    return {
                        id: quote.number,
                        client: clients.find(
                            client => client.id == quote.clientId
                        )
                            ? clients.find(
                                  client => client.id == quote.clientId
                              ).name
                            : quote.clientId,
                        name: quote.name,
                        date: quote.date,
                        createdOn: quote.createdOn,
                        updatedOn: quote.updatedOn,
                        city: quote.city,
                        status: quote.status,
                        currency: quote.currency,
                        exchange: quote.exchange,
                        total: quote.total,
                    }
                })
                const data2 = copySimpleQuotes.map(quote => {
                    return {
                        id: quote.number,
                        client: clients.find(
                            client => client.id == quote.clientId
                        )
                            ? clients.find(
                                  client => client.id == quote.clientId
                              ).name
                            : quote.clientId,
                        date:
                            quote.date.value === undefined
                                ? ''
                                : quote.date.value.substr(0, 10),
                        user: this.quotesByUser.find(
                            q => q.userId == quote.userId
                        )
                            ? this.quotesByUser.find(
                                  q => q.userId == quote.userId
                              ).nameUser
                            : '',
                        createdOn: moment
                            .unix(quote.createdOn._seconds)
                            .format('YYYY-MM-DD HH:mm'),
                        status: quote.status,
                        total: quote.total || 0,
                    }
                })
                const data3 = clientQuotes.map(quote => {
                    return {
                        client: quote.name,
                        quantity: quote.qty,
                        value: quote.value,
                        currency: 'COP',
                        percentage: quote.percentage,
                    }
                })
                //leeds
                // only array possible
                var status = XLSX.utils.json_to_sheet(data1)
                var leeds = XLSX.utils.json_to_sheet(data2)
                var clientsData = XLSX.utils.json_to_sheet(data3)

                // A workbook is the name given to an Excel file
                var wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, status, 'status') // sheetAName is name of Worksheet
                XLSX.utils.book_append_sheet(wb, leeds, 'leeds')
                XLSX.utils.book_append_sheet(wb, clientsData, 'clients')

                // export Excel file
                XLSX.writeFile(wb, `statistics.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async completeQuotesData(quotes) {
            try {
                for (const quote of quotes) {
                    quote.attached =
                        quote.files !== undefined
                            ? quote.files.filter(x => x.attach == true)
                            : []
                    quote.docs =
                        quote.files !== undefined
                            ? quote.files.filter(x => x.attach == false)
                            : []
                    quote.sequence = quote.createdOn._seconds
                    if (!quote.number) {
                        quote.shortId = quote.createdOn._seconds
                            .toString(16)
                            .toUpperCase()
                    }
                    // quote.date = moment
                    //     .unix(quote.createdOn._seconds)
                    //     .format('MMM/DD/YYYY')
                    quote.createdOn = moment
                        .unix(quote.createdOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')

                    if (quote.updatedOn) {
                        quote.updatedOn = moment
                            .unix(quote.updatedOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    }
                    quote.dataCollaborators = []
                    if (quote.collaborators) {
                        quote.collaborators.forEach(element => {
                            const dataUser = this.users.find(
                                user => element == user.id
                            )
                            if (dataUser) quote.dataCollaborators.push(dataUser)
                        })
                    }
                    if (quote.userId) {
                        quote.mainContact = this.users.find(
                            user => quote.userId == user.id
                        )
                    }
                    quote.users = this.users
                    await this.loadClientsAndContacts(quote)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openQuotesBySection(section, quotesToFilter = null) {
            this.dialogQuotesBySection = true
            this.section = section
            let quotes
            if (quotesToFilter) {
                quotes = quotesToFilter.filter(
                    q => q.status == section.toUpperCase()
                )
            } else {
                quotes = this.completeQuotes.filter(
                    q => q.status == section.toUpperCase()
                )
            }

            this.setFromMainBoardAction(false)
            this.$router.push({
                name: 'quotesfromStatistics',
                params: {
                    ...(section == 'sent' ? { type: 'followUp' } : {}),
                    quotes: this.restructureQuotes(quotes),
                    settings: this.settings,
                },
            })
        },
        backToStatistics() {
            this.dialogQuotesBySection = false
            this.dialogQuote = false
            this.$router
                .replace({
                    name: 'statistics',
                })
                .catch(error => error && null)
        },
        restructureQuotes(quotes) {
            return quotes.map(q => {
                const filteredColaborators = q.dataCollaborators.filter(
                    colaborator => colaborator.id != q.userId
                )
                return {
                    quoteId: q.id,
                    id: q.number,
                    client: q.client ? q.client.name : '',
                    project: q.name ? q.name : '',
                    city: q.city,
                    price: q.total,
                    currency: q.currency,
                    accountManager: q.mainContact ? q.mainContact.name : '',
                    collaborators: q.dataCollaborators
                        ? filteredColaborators.map(collaborator =>
                              collaborator.name ? collaborator.name : ''
                          )
                        : [],
                    tags: q.tags || [],
                    probability: q.probability,
                    expectedDate: q.expectedDate,
                    exchange: q.exchange,
                    sentDate: q.sentDate,
                    country: q.country ? q.country : '',
                }
            })
        },
        async loadClientsAndContacts(quote) {
            if (quote.clientId) {
                quote.client = this.tempClients.find(
                    client => quote.clientId == client.id
                )
            }
            quote.clients = this.tempClients
        },
        async openQuoteFromTable(quoteId) {
            let quote = this.completeQuotes.find(q => q.id === quoteId)
            if (!quote) {
                quote = this.followUpQuotes.find(q => q.id === quoteId)
            }
            return quote
        },
        async openQuote(quote, costs) {
            this.cardQuote = _.cloneDeep(quote)
            this.dialogQuote = true
            this.costs = costs
            await this.getHistoryCosts()
            this.$router.replace({
                name:
                    this.cardQuote.status !== 'SENT'
                        ? 'quoteGeneralInfo'
                        : 'quoteFollowUp',
                params: {
                    id: this.cardQuote.id,
                    quote: this.cardQuote,
                    settings: this.settings,
                },
            })
        },
        async getClients() {
            try {
                this.error = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { clients },
                } = await API.getClients()
                const {
                    data: { contacts },
                } = await API.getContacts()
                const {
                    data: { roles },
                } = await API.getRoles()
                for (const contact of contacts) {
                    contact.companyRoles = roles.roles
                }
                for (const client of clients) {
                    const clientContacts = contacts.filter(
                        contact => contact.clientId == client.id
                    )
                    client.contacts = clientContacts
                    client.companyRoles = roles.roles
                    const clientQuotes = this.completeQuotes.filter(
                        project => project.clientId == client.id
                    )

                    client.quotes = clientQuotes
                    this.tempClients.push(client)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getHistoryCosts: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { historyCosts },
                } = await API.getHistoryCosts(this.cardQuote.id)
                if (historyCosts.length > 0) {
                    historyCosts.forEach(cost => {
                        cost.createdOn = moment
                            .unix(cost.createdOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    })

                    this.addHistoryCostAction({
                        idQuote: this.cardQuote.id,
                        historyCosts: historyCosts,
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getSettings() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { settings },
                } = await API.getSettings()
                const indexLogo = settings.findIndex(x => x.name == 'Company')
                if (settings[indexLogo].generalInfo.image) {
                    const path = `${this.companyId}/${this.folderLogo}`
                    const storageRef = storage().ref(path)
                    const profilePicRef = storageRef.child(
                        settings[indexLogo].generalInfo.image.file
                    )
                    settings[
                        indexLogo
                    ].generalInfo.image.imageURL = await profilePicRef.getDownloadURL()
                }
                let orderSettings = settings.filter(x => x.name == 'Company')
                orderSettings[1] = settings.find(x => x.name == 'Exchange')
                orderSettings[2] = settings.find(x => x.name == 'Permissions')
                orderSettings[3] = settings.find(x => x.name == 'Quotes')

                const calculations = this.userRef.permissions.find(
                    x => x == 'calculations'
                )

                if (calculations) {
                    const item = settings.find(x => x.name == 'Calculations')
                    if (item) {
                        orderSettings[4] = item
                    }
                }
                const finish = settings.find(x => x.name == 'FinishAndUCCode')
                if (finish) {
                    orderSettings.push(finish)
                }
                const processes = settings.find(x => x.name == 'Processes')
                const products = settings.find(x => x.name == 'Products')
                const invoicing = settings.find(x => x.name == 'Invoicing')
                if (invoicing) {
                    orderSettings.push(invoicing)
                }

                if (processes) {
                    const machines = {
                        name: 'Machines',
                        nameRoute: 'machinesSettings',
                        id: processes.id,
                    }
                    orderSettings.push(machines)
                }

                const workOrderTab = {
                    name: 'WorkOrders',
                    nameRoute: 'workOrderSettings',
                    processes: processes || {},
                    products: products || {},
                }
                orderSettings.push(workOrderTab)
                const operatorsTab = {
                    name: 'Operators',
                    nameRoute: 'operators',
                }
                orderSettings.push(operatorsTab)
                this.addSettingsAction(orderSettings)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async replaceQuote() {
            this.flag = false
            await this.loadData()
            this.flag = true
        },
        closeSendQuote() {
            this.dialogSend = false
            this.backToStatistics()
            this.activateSuccessAlert()
        },
        activateSuccessAlert() {
            this.successEmail = true
        },
        async archiveQuote(quote = undefined) {
            try {
                this.error = false
                this.errorMsg = null
                if (quote && quote.id) {
                    this.quoteToArchive = _.cloneDeep(quote)
                }
                const { id } = this.quoteToArchive
                await API.createQuoteHistory(
                    id,
                    undefined,
                    undefined,
                    'pFDh25EcR74EMiuypq8M'
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingArchive = false
                this.archive = false
            }
        },
    },
}
</script>
